<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DefaultFilters",
  data: function () {
    return {

    };
  },
  methods: {
  },
};
</script>

<style scoped>
</style>