var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-filter box1" }, [
      _c("div", { staticClass: "form mb-0", attrs: { action: "#" } }, [
        _c("h4", [_vm._v("Filter By")]),
        _c("div", { staticClass: "filter-options price-filter" }, [
          _c("h6", [_vm._v("Price")]),
          _c("input", {
            staticClass: "form-control-range",
            attrs: { type: "range" }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }